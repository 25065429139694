<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <a @click="goBack" class="margin-bottom" style="border: 1px black"><span class="fa fa-long-arrow-left"></span>
          Back</a>
        <br />
        <br />
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>Edit IP List</strong>
            </div>
            <div class="text-muted">Edit IP List information</div>
          </div>
          <div class="card-body" v-if="ipListData">
            <a-form :form="form" @submit.prevent="handleSubmit">
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Name">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>IP list's name.</span>
                  </template>
                  <a-input type="text" placeholder="IP List Name" v-decorator="['name', {
                    initialValue: ipListData.Name,
                    rules: [
                      { required: true, message: 'Name is required.' },
                      { max: 64, message: 'Maximum 64 characters allowed.' }
                    ]
                  }]" />
                </a-tooltip>
              </a-form-item>
              <div class="ant-row ant-form-item">
                <div class="ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"></div>
                <div class="ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper">
                  <button type="submit" class="btn btn-success px-5">Save Changes</button>
                  <button type="button" :class="'btn btn-' + (ipListData.Status === 0 ? 'danger' : 'primary') + ' px-5 ml-2'"
                    @click.prevent="toggleIPListStatus">
                    {{ ipListData.Status == 0 ? 'Deactivate' : 'Activate' }} IP List
                  </button>
                  <button type="button" class="btn px-5 ml-2" @click="$router.push({ name: 'display-iplists' })">
                    Cancel
                  </button>
                </div>
              </div>
            </a-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'ant-design-vue'
import { getIpListById } from '@/api/display/iplist'

export default {
  components: {},
  data() {
    return {
      ipListData: null,
      form: this.$form.createForm(this),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 3 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$store.dispatch('ORTBiplist/UPDATE_IPLIST', {
            id: this.ipListData.Id,
            payload: {
              ...values,
            },
            callback: function () {
              this.$router.push({ name: 'display-iplists' }).catch(() => {
              })
            }.bind(this),
            // @click="$router.push('/ads')"
          })
        }
      })
    },
    toggleIPListStatus() {
      const status = this.ipListData.Status === 0 ? 'Deactivate' : 'Activate'
      Modal.confirm({
        title: 'Are you sure you want to change IP list status?',
        content: h => <div>{status} IP list <strong>{this.ipListData.Name}</strong>?</div>,
        onOk: function () {
          const newStatus = this.ipListData.Status === 0 ? 1 : 0
          this.ipListData.Status = newStatus
          this.$store.dispatch('ORTBiplist/CHANGE_STATUS', {
            id: this.ipListData.Id,
            status: newStatus,
          })
        }.bind(this),
      })
    },
  },
  created() {
    getIpListById(this.$route.params.id)
      .then((res) => {
        this.ipListData = res
      })
  },
}
</script>
<style lang="css" scoped>
.ant-form-item {
  margin-bottom: 5px !important;
}
</style>
